let version = {
    software: "test-v1.1.27-beta2-1cfa169b", // ATTENTION: 切勿修改此值，在gitlab构建时，会自动替换成对应版本
    sip: "vmeeting-v1.1.6", // ATTENTION: 切勿修改此值，在gitlab构建时，会自动替换成对应版本
    environment: process.env.REACT_APP_ENV,

    versionNo() {
        let ver = 0;
        try {
            let vers = this.software.match(/v(\S*)-/)[1].split('-')[0].split('.').reverse();
            for (let index in vers) {
                ver += parseInt(vers[index]) * Math.pow(100, index);
            }
        } catch (error) {
            ver = 1;
        }

        return ver;
    },

    isProduction() {
        return this.environment === "production";
    },

    isInternal() {
        return this.environment === "internal";
    },

    isDevelopment() {
        return this.environment === "development";
    },
}

let config = {
    
    refreshTokenInterval: 1200, // 单位：秒

    host: {
        websocket: "wss://test.ludiqiao.com",
        cs: "https://test.ludiqiao.com",
        redirect: "https://vmeeting.ludiqiao.com/goto_test",
    },

    call: {
        duration: {
            max: 8 * 3600, // 最大通话时长。单位：秒（默认8小时）
            expireTipDelta: 5 * 60, // 超时之前多久提示用户。单位：秒（默认5分钟）
            expireCheckInterval: 60, // 超时检查间隔。单位：秒（默认1分钟）
        },
        allowDirectCall: true, // 是否允许SIP直呼
        maxDialHistory: 1, // 单位：个
    },

    sipml: {
        logLevel: "trace", // "fatal", "info", "warn", "error", "debug", "trace"
    },

    websocket: {
        heartbeatInterval: 20, // 单位：秒
    },

    upload: {
        region: 'oss-cn-shenzhen',
        accessKeyId: 'LTAIInySYbttfIvB',
        accessKeySecret: 'LFbM6utT5uEfawWrgaObJbEiteRgN1',
        bucket: 'tv-client-log',
        dir: `vmeeting/${version.environment}/`, // 上传目录
        suffix: '.log', // 文件后缀名
    },

    preview: {
        definition: { // 当无会议时，本地视频的分辨率
            width: 1280, 
            height: 720,
        }
    },

    rtc: { // rtc统计数据
        dropFristAmount: 3, // 丢掉前N个数据
        dataAmount: 600, // 总数据容量
    }
}

function updateHostEnv(host) {
    console.log("update host", host);
    if (host?.ip && host?.port) {
        config.host.cs = `https://${host.ip}:${host.port}`;
        config.host.websocket = `wss://${host.ip}:${host.port}`;
        window.localStorage && window.localStorage.setItem("vmeeting::csIp", host.ip);
        window.localStorage && window.localStorage.setItem("vmeeting::csPort", host.port);
    }
}

function switchEnv(env, force) {

    switch (env) {
        case "development":
            config.host.cs = "https://test.ludiqiao.com";
            config.host.websocket = "wss://test.ludiqiao.com";

            // config.host.cs = "https://show.ludiqiao.com";
            // config.host.websocket = "wss://show.ludiqiao.com";

            // config.host.cs = "https://ludiqiao.com";
            // config.host.websocket = "wss://ludiqiao.com";

            break;

        case "internal": {
            let csIp = window.location.hostname;
            let csPort = "6443";
            let wsIp = window.location.hostname;
            let wsPort = "6443";
            if (window.localStorage) {
                csIp = window.localStorage.getItem("vmeeting::csIp") || csIp;
                csPort = window.localStorage.getItem("vmeeting::csPort") || csPort;
                wsIp = window.localStorage.getItem("vmeeting::wsIp") || wsIp;
                wsPort = window.localStorage.getItem("vmeeting::wsPort") || wsPort;
            }
            config.host.cs = `https://${csIp}:${csPort}`;
            config.host.websocket = `wss://${wsIp}:${wsPort}`;
            break;
        }

        case "production":
            config.sipml.logLevel = "info";
            config.host.cs = "https://ludiqiao.com";
            config.host.websocket = "wss://ludiqiao.com";
            config.host.redirect = "https://" + window.location.host + window.location.pathname;
            config.call.allowDirectCall = false;
            break;

        case "test":
            config.host.cs = "https://test.ludiqiao.com";
            config.host.websocket = "wss://test.ludiqiao.com";
            config.host.redirect = "https://vmeeting.ludiqiao.com/goto_test";
            break;

        case "show":
            config.host.cs = "https://show.ludiqiao.com";
            config.host.websocket = "wss://show.ludiqiao.com";
            config.host.redirect = "https://vmeeting.ludiqiao.com/goto_show";
            break;

        default:
            return;
    }

    if (window.localStorage && !force) {
        let csIp = window.localStorage.getItem("vmeeting::csIp");
        let csPort = window.localStorage.getItem("vmeeting::csPort");
        let wsIp = window.localStorage.getItem("vmeeting::wsIp");
        let wsPort = window.localStorage.getItem("vmeeting::wsPort");

        if (csIp && csPort && wsIp && wsPort) {
            config.host.cs = `https://${csIp}:${csPort}`;
            config.host.websocket = `wss://${wsIp}:${wsPort}`;
        }
    }

    version.environment = env;
}

switchEnv(version.environment);
export { config, version, switchEnv, updateHostEnv };

window.config = config;